import * as React from "react"
import { API_UNCORRECTS } from "../global/api"

import LayoutSubpage from "../layouts/layout_subpage"
import Indexes from "../components/Indexes/Indexes"

const IndexPage = () => (
  <LayoutSubpage title="Indeks form niepoprawnych">
    <Indexes dataUrl={API_UNCORRECTS} title="Indeks form niepoprawnych" />
  </LayoutSubpage>
)

export default IndexPage
